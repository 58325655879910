<template>
  <footer class="footer has-text-white">
    <div class="columns">
      <div class="column has-text-centered">
        <div class="social-icons" style="margin-bottom: 20px;">
            <a href="https://twitter.com/aadjemonkeyrock">
              <i class="fab fa-lg fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/aadjemonkeyrock/">
              <i class="fab fa-lg fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/channel/UCNP8ezZTiidpEh5UJCGYoow">
              <i class="fab fa-lg fa-youtube"></i>
            </a>
            <a href="https://www.linkedin.com/in/aadjemonkeyrock/">
              <i class="fab fa-lg fa-linkedin"></i>
            </a>
        </div>
        <div>{{$t('Handmade with')}} <i class="far fa-heart"></i> {{$t('in Rotterdam, The Netherlands')}}</div>
      </div>
      <div class="column has-text-centered">
        <div style="margin-bottom: 20px;">{{$t('Made with')}} <a href="https://bulma.io" target="_blank"><strong>Bulma</strong></a> {{$t('and')}} <a href="https://vuejs.org" target="_blank"><strong>Vue</strong></a>, {{$t('hosted on')}} <a href="https://cloud.google.com/appengine/" target="_blank"><strong>Google AppEngine</strong></a></div>
        <div><i class="far fa-copyright"></i> Aadjemonkeyrock 2011 - {{year}} - <router-link to="/privacy">{{ $t('Privacy statement')}}</router-link></div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'x-footer',
  computed: {
    year () {
      const d = new Date()
      return d.getFullYear()
    }
  }
}
</script>

<style scoped>
</style>
