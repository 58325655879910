import { createApp } from 'vue'
import App from './app'
import router from './router'

import { translate } from './system/translator'
import 'cookieconsent'

const app = createApp(App)
app.use(router)
app.config.globalProperties.$t = translate
app.mount('#app')

document.addEventListener('DOMContentLoaded', () => {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#ebfeff',
        text: '#00ACB8'
      },
      button: {
        background: '#00ACB8',
        text: '#ffffff'
      }
    },
    position: 'bottom',
    theme: 'classic',
    content: {
      message: translate('This website uses cookies to ensure you get the best experience on our website'),
      dismiss: translate('Got it!'),
      link: translate('Our privacy policy.'),
      href: '/privacy',
      target: '_self'
    }
  })
})
