<template>
  <section v-if="story !== null" class="section has-background-primary-lighter">
    <div class="container" data-aos="zoom-in-right" data-aos-once="true">
      <h1 class="title has-text-centered">{{ $t('Education')}}</h1>
      <div class="content">
        <figure class="image is-pulled-left is-128x128" style="margin: 0 1.5rem 0 0;" >
          <img :src="require('@/assets/' + story.image)" />
        </figure>
        <h4>{{story.title}}</h4>
        <p class="content" v-html="story.content"></p>
      </div>
    </div>
  </section>
</template>

<script>
import { getStory } from '../../system/stories'

export default {
  name: 'today',
  data () {
    return {
      story: null,
      img: 'hogeschoolrotterdam'
    }
  },
  created () {
    this.story = getStory('education')
  }
}
</script>

<style scoped>

</style>
