// Translates the text inside the javascript
import { TRANSLATED_MESSAGES as nl } from '../locale/nl'
import { TRANSLATED_MESSAGES as en } from '../locale/en'
import { getLocale } from './locale'

const languages = {
  nl,
  en
}
const translations = languages[getLocale()]

export function translate (message) {
  if (translations !== undefined && translations[message]) {
    return translations[message]
  }
  return message
}
