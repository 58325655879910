/* eslint-disable */export const TRANSLATED_MESSAGES = {
    "Analytical cookies": "Analytical cookies",
    "Analytical cookies allow us to measure the website visit. I use Google Analytics and collect which pages you view, how you ended up here and some basic information about your computer.": "Analytical cookies allow us to measure the website visit. I use Google Analytics and collect which pages you view, how you ended up here and some basic information about your computer.",
    "and": "and",
    "Any questions?": "Any questions?",
    "Automated decision-making": "Automated decision-making",
    "Cookies": "Cookies",
    "Early career": "Early career",
    "Education": "Education",
    "Entrepreneur": "Entrepreneur",
    "Everyone wants to innovate.": "Everyone wants to innovate.",
    "For what purpose and on what basis I process personal data": "For what purpose and on what basis I process personal data",
    "Functional cookies": "Functional cookies",
    "Functional cookies are necessary for this website to function properly. These cookies are placed without permission. Functional cookies ensure, among other things, that you only have to give permission once for the cookies that I have placed.": "Functional cookies are necessary for this website to function properly. These cookies are placed without permission. Functional cookies ensure, among other things, that you only have to give permission once for the cookies that I have placed.",
    "Furthermore, I will not provide the information you provide to other parties, unless this is legally required or permitted. For example, it is possible that the police will request information from me in the context of fraud investigations. In that case, I am legally obliged to provide this information.": "Furthermore, I will not provide the information you provide to other parties, unless this is legally required or permitted. For example, it is possible that the police will request information from me in the context of fraud investigations. In that case, I am legally obliged to provide this information.",
    "Google Analytics data is automatically deleted 26 months after your last visit.": "Google Analytics data is automatically deleted 26 months after your last visit.",
    "Got it!": "Got it!",
    "Handmade with": "Handmade with",
    "Hi,": "Hi,",
    "hosted on": "hosted on",
    "How I secure personal data": "How I secure personal data",
    "How long I keep personal data": "How long I keep personal data",
    "I anonymize the data in Google Analytics - so that we do not know who you are; you are just someone who visits the site.": "I anonymize the data in Google Analytics - so that we do not know who you are; you are just someone who visits the site.",
    "I do not store your personal data for longer than is strictly necessary to realize the purposes for which your data is collected. With the exception of data that I have to keep longer because the law obliges me to do so.": "I do not store your personal data for longer than is strictly necessary to realize the purposes for which your data is collected. With the exception of data that I have to keep longer because the law obliges me to do so.",
    "I do not use automated decision-making": "I do not use automated decision-making",
    "I may update this privacy policy from time-to-time, particularly as technology changes. You can always check this page for the latest version. I may also notify website visitors of changes to my privacy policy": "I may update this privacy policy from time-to-time, particularly as technology changes. You can always check this page for the latest version. I may also notify website visitors of changes to my privacy policy",
    "I only provide to third parties if this is necessary for the execution of my agreement with you or to comply with a legal obligation. All e-mail messages that I receive and send are stored in Google's G Suite cloud service.": "I only provide to third parties if this is necessary for the execution of my agreement with you or to comply with a legal obligation. All e-mail messages that I receive and send are stored in Google's G Suite cloud service.",
    "I only use technical, functional and analytical cookies that do not infringe your privacy. A cookie is a small text file that is stored on your computer, tablet or smartphone when you first visit this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website works properly and remember, for example, your preferences. We can also optimize our website with this. You can opt out of cookies by setting your internet browser so that it does not store cookies anymore. In addition, you can also delete all information previously saved via the settings of your browser.": "I only use technical, functional and analytical cookies that do not infringe your privacy. A cookie is a small text file that is stored on your computer, tablet or smartphone when you first visit this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website works properly and remember, for example, your preferences. We can also optimize our website with this. You can opt out of cookies by setting your internet browser so that it does not store cookies anymore. In addition, you can also delete all information previously saved via the settings of your browser.",
    "I take the protection of your data seriously and take appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you feel that your data is not properly secured or there are indications of abuse, please contact us at privacy@aadjemonkeyrock.com": "I take the protection of your data seriously and take appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you feel that your data is not properly secured or there are indications of abuse, please contact us at privacy@aadjemonkeyrock.com",
    "I would also like to point out that you have the opportunity to file a complaint with the national supervisory authority, the Dutch Data Protection Authority. This can be done via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons": "I would also like to point out that you have the opportunity to file a complaint with the national supervisory authority, the Dutch Data Protection Authority. This can be done via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons",
    "I, Aad 't Hart (Aadjemonkeyrock), based in Delft. Registered in the commercial register of the Chamber of Commerce under number 52493245, am responsible for the processing of personal data as shown in this privacy statement.": "I, Aad 't Hart (Aadjemonkeyrock), based in Delft. Registered in the commercial register of the Chamber of Commerce under number 52493245, am responsible for the processing of personal data as shown in this privacy statement.",
    "I'm Aad 't Hart": "I'm Aad 't Hart",
    "If you have any questions about this privacy policy or your personal data, please email to privacy@aadjemonkeyrock.com.": "If you have any questions about this privacy policy or your personal data, please email to privacy@aadjemonkeyrock.com.",
    "Innovator": "Innovator",
    "Made with": "Made with",
    "Nobody wants to change!": "Nobody wants to change!",
    "Our privacy policy.": "Our privacy policy.",
    "Personal data that I process": "Personal data that I process",
    "Privacy is important. You want to know what happens to your data. I always deal confidentially with your information.": "Privacy is important. You want to know what happens to your data. I always deal confidentially with your information.",
    "Privacy policy": "Privacy policy",
    "Sharing personal data with third parties": "Sharing personal data with third parties",
    "The collected non-personal visitor statistics are used to optimize this website.": "The collected non-personal visitor statistics are used to optimize this website.",
    "The statistics give me insight into how often the website is used and what information visitors are looking for.": "The statistics give me insight into how often the website is used and what information visitors are looking for.",
    "This website uses cookies to ensure you get the best experience on our website": "This website uses cookies to ensure you get the best experience on our website",
    "Today": "Today",
    "Traveller": "Traveller",
    "Updates to this policy": "Updates to this policy",
    "View, modify or delete data": "View, modify or delete data",
    "When visiting this website, non-personal (anonymous) information such as the type of web browser, language preferences, the origin site and the date and time of the website visit will be collected.": "When visiting this website, non-personal (anonymous) information such as the type of web browser, language preferences, the origin site and the date and time of the website visit will be collected.",
    "You can send me an email, I will process your name in combination with your email address.": "You can send me an email, I will process your name in combination with your email address.",
    "You have the right to view, correct or delete your personal data. You also have the right to withdraw your consent to data processing. In these cases you can contact me via privacy@aadjemonkeyrock.com. To prevent abuse, I can ask you to adequately identify yourself.": "You have the right to view, correct or delete your personal data. You also have the right to withdraw your consent to data processing. In these cases you can contact me via privacy@aadjemonkeyrock.com. To prevent abuse, I can ask you to adequately identify yourself.",
    "Your name and email are stored and used to answer you.": "Your name and email are stored and used to answer you."
}