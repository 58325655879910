<template>
   <section v-if="story !== null" class="section">
     <div class="container">
      <h1 class="title has-text-centered">{{ $t('Early career')}}</h1>
        <div class="card" data-aos="zoom-in-left" data-aos-once="true">
          <div class="card-content">
            <div class="content">
              <figure class="image is-pulled-left is-128x128" style="margin: 0 1.5rem 0 0;">
                <img class="is-rounded" :src="require('@/assets/' + story.image)" :alt="story.title" />
              </figure>
              <div class="content">
                <h4>{{story.title}}</h4>
                <p v-html="story.content"></p>
              </div>
            </div>
          </div>
        </div>
     </div>
   </section>
</template>

<script>
import { getStory } from '../../system/stories'

export default {
  name: 'today',
  data () {
    return {
      story: null
    }
  },
  created () {
    this.story = getStory('tno')
  }
}
</script>

<style scoped>

</style>
