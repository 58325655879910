import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/home.vue'
import Privacy from './views/privacy'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/', name: 'home', component: Home
    },
    {
      path: '/privacy', name: 'privacy', component: Privacy
    },
    { path: '/*', redirect: '/' } // Always go Home ;-)
  ]
})

export default router
