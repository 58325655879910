<template>
  <header>
    <nav class="navbar is-transparent is-fixed-top">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item brand-icon"></router-link>
      </div>
    </nav>
    <nav class="navbar is-fixed-top" data-aos="fade" data-aos-anchor-placement="bottom-top">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item brand-icon">
          <span class="brand-name">Aad 't Hart</span>
        </router-link>
      </div>
      <div class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item">
              <a class="button is-white" :class="{ bold: language==='en',}" @click="setLanguage('en')">
                EN
              </a>
              <a class="button is-white" :class="{ bold: language==='nl',}" @click="setLanguage('nl')">
                NL
              </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script lang="js">
import { getLocale, setLocale } from '@/system/locale'

export default {
  name: 'x-header',
  data () {
    return {
      language: null
    }
  },
  created () {
    this.language = getLocale()
  },
  methods: {
    setLanguage (language) {
      this.language = language

      setLocale(language)
      // window.re
      window.location.reload()
    }
  }
}
</script>

<style scoped>
  .bold {
    font-weight: bold
  }
</style>
