<template>
   <section v-if="stories.length > 0" class="section">
     <div class="container">
      <h1 class="title has-text-centered">2011 - 2018</h1>
      <div class="columns">
        <div v-for="(story, index) in stories" :key="story.id" class="column" :data-aos="zooms[index]" data-aos-once="true" >
          <div class="card">
            <div class="card-content">
              <div class=card-image>
                <figure class="image">
                  <img :src="require('@/assets/' + story.image)" :alt="story.title">
                </figure>
              </div>
            </div>
            <div class="card-content">
              <p class="content" v-html="story.content"></p>
            </div>
          </div>
        </div>
      </div>
     </div>
   </section>
</template>

<script>
import { getStory } from '../../system/stories'

export default {
  name: 'today',
  data () {
    return {
      stories: [],
      zooms: ['zoom-in-up', 'zoom-in-down']
    }
  },
  created () {
    this.stories.push(getStory('bitcare'))
    this.stories.push(getStory('humanwave'))
  }
}
</script>

<style scoped>

</style>
