<template>
  <div class="home">
    <intro></intro>
    <about></about>
    <today></today>
    <yuki></yuki>
    <x-2011-2018></x-2011-2018>
    <x-1998-2010></x-1998-2010>
    <earlier></earlier>
    <education></education>
  </div>
</template>

<script>
import Intro from '@/components/home/intro'
import About from '@/components/home/about'
import Today from '@/components/home/today'
import Yuki from '@/components/home/yuki'
import X2011_2018 from '@/components/home/2011-2018'
import X1998_2010 from '@/components/home/1998-2010'
import Earlier from '@/components/home/earlier'
import Education from '@/components/home/education'

export default {
  name: 'home',
  components: {
    intro: Intro,
    about: About,
    today: Today,
    yuki: Yuki,
    'x-2011-2018': X2011_2018,
    'x-1998-2010': X1998_2010,
    earlier: Earlier,
    education: Education
  }
}
</script>
