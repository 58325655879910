<template>
    <section v-if="story !== null" class="section has-background-primary-lighter">
      <div class="container" data-aos="zoom-in" data-aos-once="true">
        <h1 class="title has-text-centered">2019 - 2022</h1>
        <figure class="image" style="margin: 0 auto 1.5rem; width: 240px;">
          <img :src="require('@/assets/' + story.image)" :alt="story.title" />
        </figure>
        <p class="content has-text-centered" v-html="story.content"></p>
      </div>
    </section>
  </template>

<script>
import { getStory } from '../../system/stories'

export default {
  name: 'yuki',
  data () {
    return {
      story: null
    }
  },
  created () {
    this.story = getStory('yuki')
  }
}
</script>

  <style scoped>

  </style>
