<template>
   <section v-if="story != null" class="section">
     <div class="container" data-aos="zoom-in" data-aos-once="true">
      <h1 class="title has-text-centered">{{story.title}}</h1>
      <figure class="image avatar" style="margin: 0 auto 2rem;">
        <img class="is-rounded" :src="require('@/assets/' + story.image)" :alt="story.title" />
      </figure>
      <p class="content has-text-centered" v-html="story.content"></p>
     </div>
   </section>
</template>

<script>
import { getStory } from '../../system/stories'

export default {
  name: 'about',
  data () {
    return {
      story: null
    }
  },
  created () {
    this.story = getStory('about')
  }
}
</script>
