/* eslint-disable */export const TRANSLATED_MESSAGES = {
    "Analytical cookies": "Analytische cookies",
    "Analytical cookies allow us to measure the website visit. I use Google Analytics and collect which pages you view, how you ended up here and some basic information about your computer.": "Analytische cookies stellen mij in staat om het bezoek van de website te meten. Ik gebruik Google Analytics en verzamel de pagina’s u bekijkt, hoe u hier bent beland en wat basisinformatie over uw computer.",
    "and": "en",
    "Any questions?": "Nog vragen?",
    "Automated decision-making": "Geautomatiseerde besluitvorming",
    "Cookies": "Cookies",
    "Early career": "Vroege carrière",
    "Education": "Onderwijs",
    "Entrepreneur": "Ondernemer",
    "Everyone wants to innovate.": "Iedereen wil vernieuwen.",
    "For what purpose and on what basis I process personal data": "Voor welk doel en op welke basis ik persoonlijke gegevens verwerk",
    "Functional cookies": "Functionele cookies",
    "Functional cookies are necessary for this website to function properly. These cookies are placed without permission. Functional cookies ensure, among other things, that you only have to give permission once for the cookies that I have placed.": "Functionele cookies zijn nodig om deze website goed te laten werken. Deze cookies worden zonder toestemming geplaatst. Functionele cookies zorgen er onder andere voor dat je slechts één keer toestemming hoeft te geven voor de cookies die ik heb geplaatst.",
    "Furthermore, I will not provide the information you provide to other parties, unless this is legally required or permitted. For example, it is possible that the police will request information from me in the context of fraud investigations. In that case, I am legally obliged to provide this information.": "Bovendien zal ik de informatie die u aan andere partijen verstrekt niet verstrekken, tenzij dit wettelijk verplicht of toegestaan is. Het is bijvoorbeeld mogelijk dat de politie mij om informatie zal vragen in het kader van fraudeonderzoeken. In dat geval ben ik wettelijk verplicht om deze informatie te verstrekken.",
    "Google Analytics data is automatically deleted 26 months after your last visit.": "Google Analytics-gegevens worden automatisch verwijderd 26 maanden na uw laatste bezoek.",
    "Got it!": "Begrepen!",
    "Handmade with": "Handgemaakt met",
    "Hi,": "Hallo,",
    "hosted on": "gehost op",
    "How I secure personal data": "Hoe ik persoonlijke gegevens beveilig",
    "How long I keep personal data": "Hoe lang ik persoonlijke gegevens bewaar",
    "I anonymize the data in Google Analytics - so that we do not know who you are; you are just someone who visits the site.": "Ik anonimiseer de gegevens in Google Analytics - zodat we niet weten wie je bent; u bent gewoon iemand die de site bezoekt.",
    "I do not store your personal data for longer than is strictly necessary to realize the purposes for which your data is collected. With the exception of data that I have to keep longer because the law obliges me to do so.": "Ik bewaar uw persoonlijke gegevens niet langer dan strikt noodzakelijk is om de doeleinden waarvoor uw gegevens worden verzameld te realiseren. Met uitzondering van gegevens die ik langer moet bewaren omdat de wet mij verplicht dat te doen.",
    "I do not use automated decision-making": "Ik maak geen gebruik van geautomatiseerde besluitvorming",
    "I may update this privacy policy from time-to-time, particularly as technology changes. You can always check this page for the latest version. I may also notify website visitors of changes to my privacy policy": "Ik kan dit privacybeleid van tijd tot tijd bijwerken, vooral als de technologie verandert. U kunt deze pagina altijd controleren op de nieuwste versie. Ik kan ook bezoekers van de website op de hoogte brengen van wijzigingen in mijn privacybeleid",
    "I only provide to third parties if this is necessary for the execution of my agreement with you or to comply with a legal obligation. All e-mail messages that I receive and send are stored in Google's G Suite cloud service.": "Ik geef alleen informatie aan derden als dit nodig is voor de uitvoering van mijn overeenkomst met u of om te voldoen aan een wettelijke verplichting. Alle e-mailberichten die ik ontvang en verzend, worden opgeslagen in de G Suite-cloudservice van Google.",
    "I only use technical, functional and analytical cookies that do not infringe your privacy. A cookie is a small text file that is stored on your computer, tablet or smartphone when you first visit this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website works properly and remember, for example, your preferences. We can also optimize our website with this. You can opt out of cookies by setting your internet browser so that it does not store cookies anymore. In addition, you can also delete all information previously saved via the settings of your browser.": "Ik gebruik alleen technische, functionele en analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat op uw computer, tablet of smartphone wordt opgeslagen wanneer u deze website voor het eerst bezoekt. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website goed werkt en onthouden bijvoorbeeld uw voorkeuren. We kunnen onze website hiermee ook optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Bovendien kunt u ook alle eerder opgeslagen informatie verwijderen via de instellingen van uw browser.",
    "I take the protection of your data seriously and take appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you feel that your data is not properly secured or there are indications of abuse, please contact us at privacy@aadjemonkeyrock.com": "Ik neem de bescherming van uw gegevens serieus en neem passende maatregelen om misbruik, verlies, ongeoorloofde toegang, ongewenste openbaarmaking en ongeoorloofde wijzigingen te voorkomen. Als u van mening bent dat uw gegevens niet correct zijn beveiligd of als er aanwijzingen zijn voor misbruik, neem dan contact op via privacy@aadjemonkeyrock.com",
    "I would also like to point out that you have the opportunity to file a complaint with the national supervisory authority, the Dutch Data Protection Authority. This can be done via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons": "Ik wil er ook op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de Nederlandse autoriteit persoonsgegevens. Dit kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons",
    "I, Aad 't Hart (Aadjemonkeyrock), based in Delft. Registered in the commercial register of the Chamber of Commerce under number 52493245, am responsible for the processing of personal data as shown in this privacy statement.": "Ik, Aad ’t Hart (Aadjemonkeyrock), gevestigd in Delft. Ingeschreven in het handelsregister van de Kamer van Koophandel onder nummer 52493245, ben verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.",
    "I'm Aad 't Hart": "Ik ben Aad ’t Hart",
    "If you have any questions about this privacy policy or your personal data, please email to privacy@aadjemonkeyrock.com.": "Als u vragen hebt over dit privacybeleid of uw persoonlijke gegevens, kunt u een e-mail sturen naar privacy@aadjemonkeyrock.com.",
    "Innovator": "Innovator",
    "Made with": "Gemaakt met",
    "Nobody wants to change!": "Niemand wil veranderen!",
    "Our privacy policy.": "Ons privacybeleid.",
    "Personal data that I process": "Persoonlijke gegevens die ik verwerk",
    "Privacy is important. You want to know what happens to your data. I always deal confidentially with your information.": "Privacy is belangrijk. U wilt weten wat er met uw gegevens gebeurt. Ik ga altijd vertrouwelijk om met uw informatie.",
    "Privacy policy": "Privacybeleid",
    "Sharing personal data with third parties": "Persoonlijke gegevens delen met derden",
    "The collected non-personal visitor statistics are used to optimize this website.": "De verzamelde niet-persoonlijke bezoekersstatistieken worden gebruikt om deze website te optimaliseren.",
    "The statistics give me insight into how often the website is used and what information visitors are looking for.": "De statistieken geven me inzicht in hoe vaak de website wordt gebruikt en welke informatie bezoekers zoeken.",
    "This website uses cookies to ensure you get the best experience on our website": "Deze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt",
    "Today": "Vandaag",
    "Traveller": "Reiziger",
    "Updates to this policy": "Updates voor dit beleid",
    "View, modify or delete data": "Gegevens bekijken, wijzigen of verwijderen",
    "When visiting this website, non-personal (anonymous) information such as the type of web browser, language preferences, the origin site and the date and time of the website visit will be collected.": "Wanneer u deze website bezoekt, wordt niet-persoonlijke (anonieme) informatie zoals het type webbrowser, taalvoorkeuren, de oorsprongssite en de datum en tijd van het websitebezoek verzameld.",
    "You can send me an email, I will process your name in combination with your email address.": "U kunt mij een e-mail sturen, ik zal uw naam verwerken in combinatie met uw e-mailadres.",
    "You have the right to view, correct or delete your personal data. You also have the right to withdraw your consent to data processing. In these cases you can contact me via privacy@aadjemonkeyrock.com. To prevent abuse, I can ask you to adequately identify yourself.": "U hebt het recht om uw persoonlijke gegevens te bekijken, te corrigeren of te verwijderen. U hebt ook het recht om uw toestemming voor gegevensverwerking in te trekken. In deze gevallen kunt u contact met mij opnemen via privacy@aadjemonkeyrock.com. Om misbruik te voorkomen, kan ik u vragen om uzelf voldoende te identificeren.",
    "Your name and email are stored and used to answer you.": "Uw naam en e-mailadres worden opgeslagen en gebruikt om u te antwoorden."
}