<template>
  <section class="columns">
    <div class="column is-offset-1 is-10">
      <div class="content" style="padding: 1em;">
        <h3>{{ $t('Privacy policy')}}</h3>
        <p>
          {{$t("Privacy is important. You want to know what happens to your data. I always deal confidentially with your information.")}}
        </p>
        <p>
          {{$t("I, Aad 't Hart (Aadjemonkeyrock), based in Delft. Registered in the commercial register of the Chamber of Commerce under number 52493245, am responsible for the processing of personal data as shown in this privacy statement.")}}
        </p>

        <h4>{{ $t("Personal data that I process")}}</h4>
        <ol>
          <li>{{$t("When visiting this website, non-personal (anonymous) information such as the type of web browser, language preferences, the origin site and the date and time of the website visit will be collected.")}}</li>
          <li>{{$t("You can send me an email, I will process your name in combination with your email address.")}}</li>
        </ol>

        <h4>{{ $t("For what purpose and on what basis I process personal data")}}</h4>
        <ol>
          <li>{{$t("The collected non-personal visitor statistics are used to optimize this website.")}}</li>
          <li>{{$t("Your name and email are stored and used to answer you.")}}</li>
        </ol>

        <h4>{{ $t("Automated decision-making")}}</h4>
        <p>
          {{$t("I do not use automated decision-making")}}
        </p>

        <h4>{{ $t("How long I keep personal data")}}</h4>
        <p>
          {{$t("I do not store your personal data for longer than is strictly necessary to realize the purposes for which your data is collected. With the exception of data that I have to keep longer because the law obliges me to do so.")}}
        </p>

        <h4>{{ $t("Sharing personal data with third parties")}}</h4>
        <p>
          {{$t("I only provide to third parties if this is necessary for the execution of my agreement with you or to comply with a legal obligation. All e-mail messages that I receive and send are stored in Google's G Suite cloud service.")}}
        </p>
        <p>
          {{$t("Furthermore, I will not provide the information you provide to other parties, unless this is legally required or permitted. For example, it is possible that the police will request information from me in the context of fraud investigations. In that case, I am legally obliged to provide this information.")}}
        </p>

        <h4>{{ $t("Cookies")}}</h4>
        <p>
          {{$t("I only use technical, functional and analytical cookies that do not infringe your privacy. A cookie is a small text file that is stored on your computer, tablet or smartphone when you first visit this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website works properly and remember, for example, your preferences. We can also optimize our website with this. You can opt out of cookies by setting your internet browser so that it does not store cookies anymore. In addition, you can also delete all information previously saved via the settings of your browser.")}}
        </p>
        <h5>{{ $t("Functional cookies")}}</h5>
        <p>
          {{$t("Functional cookies are necessary for this website to function properly. These cookies are placed without permission. Functional cookies ensure, among other things, that you only have to give permission once for the cookies that I have placed.")}}
        </p>
        <h5>{{ $t("Analytical cookies")}}</h5>
        <p>
          {{$t("Analytical cookies allow us to measure the website visit. I use Google Analytics and collect which pages you view, how you ended up here and some basic information about your computer.")}}
          {{$t("I anonymize the data in Google Analytics - so that we do not know who you are; you are just someone who visits the site.")}}
          {{$t("The statistics give me insight into how often the website is used and what information visitors are looking for.")}}
          {{$t("Google Analytics data is automatically deleted 26 months after your last visit.")}}
        </p>
        <h4>{{ $t("View, modify or delete data")}}</h4>

        <p>
          {{$t("You have the right to view, correct or delete your personal data. You also have the right to withdraw your consent to data processing. In these cases you can contact me via privacy@aadjemonkeyrock.com. To prevent abuse, I can ask you to adequately identify yourself.")}}
        </p>
        <p>
          {{$t("I would also like to point out that you have the opportunity to file a complaint with the national supervisory authority, the Dutch Data Protection Authority. This can be done via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons")}}
        </p>

        <h4>{{ $t("How I secure personal data")}}</h4>
        <p>
          {{$t("I take the protection of your data seriously and take appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you feel that your data is not properly secured or there are indications of abuse, please contact us at privacy@aadjemonkeyrock.com")}}
        </p>

        <h4>{{ $t("Updates to this policy")}}</h4>
        <p>
          {{$t("I may update this privacy policy from time-to-time, particularly as technology changes. You can always check this page for the latest version. I may also notify website visitors of changes to my privacy policy")}}
        </p>

        <h4>{{ $t("Any questions?")}}</h4>
        <p>
          {{$t("If you have any questions about this privacy policy or your personal data, please email to privacy@aadjemonkeyrock.com.")}}
      </p>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'privacy'
}
</script>

<style scoped>

</style>
