<template>
  <div id="app">
    <x-header></x-header>
    <router-view/>
    <x-footer></x-footer>
  </div>
</template>

<script>
import XHeader from '@/components/header'
import XFooter from '@/components/footer'
import AOS from 'aos'

export default {
  components: {
    'x-header': XHeader,
    'x-footer': XFooter
  },
  created () {
    console.log('App: created')
    AOS.init()
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
</style>
