import content from '../content/content.json'
import { getLocale } from '@/system/locale'
import { marked } from 'marked'

const stories = content.stories
const locale = getLocale()

export function getStory (name) {
  const list = stories.filter((story) => story.name === name)

  if (list.length > 0) {
    const story = list[0]

    if (locale in story) {
      return {
        title: story[locale].title,
        content: marked(story[locale].content),
        image: story[locale].image
      }
    } else {
      return {
        title: story.en.title,
        content: marked(story.en.content),
        image: story.en.image
      }
    }
  }
  return null
}
