<template>
   <section v-if="stories.length > 0" class="section has-background-primary-lighter">
     <div class="container">
      <h1 class="title has-text-centered">{{ $t('Today')}}</h1>
      <div class="columns">
        <div v-for="(story, index) in stories" :key="story.id" class="column" :data-aos="zooms[index]" data-aos-once="true">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <figure class="image is-pulled-left is-128x128" style="margin: 0 1.5rem 0 0;">
                  <img class="is-rounded" :src="require('@/assets/' + story.image)" :alt="story.title" />
                </figure>
                <div class="content">
                  <h4>{{story.title}}</h4>
                  <p v-html="story.content"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
   </section>
</template>

<script>
import { getStory } from '../../system/stories'

export default {
  name: 'today',
  data () {
    return {
      stories: [],
      zooms: ['zoom-in-right', 'zoom-in-left']
    }
  },
  created () {
    this.stories.push(getStory('freelance'))
    this.stories.push(getStory('goingwalkabout'))
  }
}
</script>

<style scoped>

</style>
