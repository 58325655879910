<template>
  <section>
    <div class="columns is-gapless is-fullheight is-home" style="margin-bottom: 0;" >
      <div class="column has-text-centered">
        <div class="columns">
          <div class="column is-8 is-offset-2" style="margin-top: 150px;">
            <h2 class="subtitle">{{ $t('Hi,')}}</h2>
            <h3 class="title is-3">{{ $t("I'm Aad 't Hart")}}</h3>
          </div>
        </div>

        <div class="level">
          <div class="level-item">
            <figure class="image avatar">
              <img class="is-rounded" src="../../assets/freelance.jpg" />
            </figure>
          </div>
        </div>

        <div class="social-icons" id="icons" style="margin: 10px 0 20px">
          <a class="button" href="https://twitter.com/aadjemonkeyrock">
            <i class="fab fa-lg fa-twitter"></i>
          </a>
          <a class="button" href="https://www.instagram.com/aadjemonkeyrock/">
            <i class="fab fa-lg fa-instagram"></i>
          </a>
          <a class="button" href="https://www.youtube.com/channel/UCNP8ezZTiidpEh5UJCGYoow">
            <i class="fab fa-lg fa-youtube"></i>
          </a>
          <a class="button" href="https://www.linkedin.com/in/aadjemonkeyrock/">
            <i class="fab fa-lg fa-linkedin"></i>
          </a>

        </div>

        <p class="subtitle" style="margin-bottom: 40px;">
          {{ $t('Entrepreneur')}} | {{ $t('Innovator')}} | {{ $t('Traveller')}}
        </p>

      </div>
      <div class="column has-background-primary has-text-white">
        <div style="display:flex; justify-content:center; align-items:center; font-size:2em; height: 100vh;">
          <div class="columns">
            <div class="column">
              <div class="has-text-centered">{{ $t('Everyone wants to innovate.')}}</div>
              <div class="has-text-centered">{{ $t('Nobody wants to change!')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="has-text-centered"><i class="fas fa-2x fa-angle-double-down" style="position: relative; bottom: 2em; animation: floating 2s infinite;"></i></p>
    </div>
  </section>

</template>

<script>
export default {
  name: 'intro'
}
</script>
